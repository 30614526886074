<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> 
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="정비자재 마스터 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='safetyStock'">
          <span>
            <q-badge v-if="props.row.realStock < props.row.safetyStock" color="negative" label="안전재고량 부족" class="blinking" />
            {{props.row.safetyStock | toThousandFilter}}
          </span>
        </template>
        <template v-if="col.name==='ropStock'">
          <span>
            <q-badge v-if="props.row.realStock == props.row.ropStock" color="orange" label="재주문점" class="blinking" />
            {{props.row.ropStock | toThousandFilter}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'spare-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        delFlag: 'N'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '정비자재명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'price',
            field: 'price',
            label: '대표단가',
            type: 'cost',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'materialStandard',
            field: 'materialStandard',
            label: '규격',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'materialQuality',
            field: 'materialQuality',
            label: '재질',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '대표제조사',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'realStock',
            field: 'realStock',
            label: '재고량',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'safetyStock',
            field: 'safetyStock',
            label: '안전 재고량',
            type: 'custom',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'ropStock',
            field: 'ropStock',
            label: '재주문점(ROP)',
            type: 'custom',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sp.material.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비자재 마스터 상세';
      this.popupOptions.param = {
        plantCd: row ? row.plantCd : '',
        materialCd: row ? row.materialCd : '',
      };
      this.popupOptions.target = () => import(`${'./spareDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
