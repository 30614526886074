var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "정비자재 마스터 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "safetyStock"
                    ? [
                        _c(
                          "span",
                          [
                            props.row.realStock < props.row.safetyStock
                              ? _c("q-badge", {
                                  staticClass: "blinking",
                                  attrs: {
                                    color: "negative",
                                    label: "안전재고량 부족",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousandFilter")(
                                    props.row.safetyStock
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  col.name === "ropStock"
                    ? [
                        _c(
                          "span",
                          [
                            props.row.realStock == props.row.ropStock
                              ? _c("q-badge", {
                                  staticClass: "blinking",
                                  attrs: { color: "orange", label: "재주문점" },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toThousandFilter")(props.row.ropStock)
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "등록", icon: "add" },
                        on: { btnClicked: _vm.openPop },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }